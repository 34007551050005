.CellCheckbox__icon {
  display: none;
  color: var(--vkui--color_icon_tertiary);
}

.CellCheckbox__icon--on {
  color: var(--vkui--color_icon_accent);
}

.CellCheckbox__input:not(:checked) ~ .CellCheckbox__icon--off,
.CellCheckbox__input:checked ~ .CellCheckbox__icon--on {
  display: block;
}

.CellCheckbox {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}
