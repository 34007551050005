.Select {
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  font-family: var(--vkui--font_family_base);
}

.Select__el {
  display: block;
  position: absolute;
  appearance: none;
  border: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: var(--vkui_internal--z_index_form_field_side);
  font-size: 16px;
  font-family: inherit;
}

.Select__container {
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 12px;
  padding-left: 12px;
  color: var(--vkui--color_text_primary);
  box-sizing: border-box;
  position: relative;
  z-index: var(--vkui_internal--z_index_form_field_element);
  overflow: hidden;
}

.Select--hasBefore .Select__container {
  padding-left: 0;
}

.Select--hasAfter .Select__container {
  padding-right: 0;
}

.Select--multiline .Select__container {
  padding-top: 12px;
  padding-bottom: 12px;
}

.Select--sizeY-compact.Select--multiline .Select__container {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (--sizeY-compact) {
  .Select--sizeY-none.Select--multiline .Select__container {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.Select__title {
  display: block;
}

.Select:not(.Select--multiline) .Select__title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Select--empty .Select__title {
  color: var(--vkui--color_text_secondary);
}

.Select--align-right .Select__title {
  text-align: right;
}

.Select--align-center .Select__title {
  text-align: center;
}

/**
 * CMP:
 * CalendarHeader
 */
.CalendarHeader__picker .Select__container {
  padding-right: 4px;
}

.Select--align-left,
.Select__custom,
.Select__native {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}
