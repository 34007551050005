.SelectTypography--selectType-accent {
  font-size: var(--vkui--font_paragraph--font_size--regular);
  line-height: var(--vkui--font_paragraph--line_height--regular);
  font-weight: var(--vkui--font_weight_accent1);
}

:not(.SelectTypography--selectType-accent).SelectTypography {
  font-size: var(--vkui--font_headline1--font_size--regular);
  line-height: var(--vkui--font_headline1--line_height--regular);
  font-weight: var(--vkui--font_weight_accent3);
}

.SelectTypography--selectType-accent.SelectTypography--android {
  font-weight: var(--vkui--font_weight_accent2);
}

.SelectTypography--vkcom:not(.SelectTypography--selectType-accent),
.SelectTypography--sizeY-compact:not(.SelectTypography--selectType-accent) {
  font-size: var(--vkui--font_text--font_size--compact);
  line-height: var(--vkui--font_text--line_height--compact);
  font-weight: var(--vkui--font_weight_accent3);
}

@media (--sizeY-compact) {
  .SelectTypography--sizeY-none:not(.SelectTypography--selectType-accent) {
    font-size: var(--vkui--font_text--font_size--compact);
    line-height: var(--vkui--font_text--line_height--compact);
    font-weight: var(--vkui--font_weight_accent3);
  }
}

.SelectTypography--selectType-default,
.SelectTypography--selectType-plain {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}
