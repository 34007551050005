.PopperArrow {
  position: absolute;
}

.PopperArrow__in {
  content: '';
  display: block;
  width: 20px;
  height: 8px;
}

.PopperArrow[data-placement^='top'] .PopperArrow__in {
  transform: rotate(180deg);
}

.PopperArrow[data-placement^='left'] .PopperArrow__in {
  transform: rotate(90deg);
}

.PopperArrow[data-placement^='right'] .PopperArrow__in {
  transform: rotate(270deg);
}
